import { Fragment } from "react"
import LabelInput from "../LabelInput"
import { useTranslation } from "react-i18next"

export default function LabelAddress2({ required }) {
  const { t } = useTranslation()
  return (
    <Fragment>
      <LabelInput
        label={
          <div>
            {t("Address")}{" "}
            <span className="text-dark-300">
              {"("}
              {t("to town")}
              {")"}
            </span>
          </div>
        }
        required={required}
      />
    </Fragment>
  )
}
