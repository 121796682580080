import dashboard from "./dashboard.json"
import brand from "./brand-management.json"
import clientManagement from "./client-management.json"
import billingManagement from "./billing-management.json"
import userManagement from "./user-management.json"
import domainManagement from "./domain-management.json"
import approval from "./approval.json"
import notification from "./notification-management.json"
import ticket from "./support-ticket.json"
import setting from "./setting.json"
import error from "./error.json"
import menu from "./menu.json"
import profile from "./user-profile.json"
import message from "./message.json"
import dropdown from "./dropdown.json"
import authentication from "./authentication.json"
import payment from "./payment.json"
import activityLog from "./activity-log.json"
import require from "./require.json"
import status from "./status.json"

const translate = {
  ID: "ID",
  Email: "Email",
  Password: "Password",
  "Email is required": "Email is required",
  "Password is required": "Password is required",
  "Enter your password": "Enter your password",
  "Client Name": "Client Name",
  Packages: "Packages",
  "Created at": "Created at",
  "View details": "View details",
  "View Details": "View Details",
  "Forgot password": "Forgot password",
  "Remember login": "Remember login",
  "Log in your account": "Log in your account",
  "Powered by": "Powered by",
  "Edit image": "Edit image",
  OK: "OK",
  Change: "Change",
  "Choose an option": "Choose an option",
  "File size exceeds the allowed limit 2 MB": "File size exceeds the allowed limit 2 MB",
  "This file doesn't support.": "This file doesn't support.",
  "Notification Detail": "Notification Detail",
  "attachment files": "attachment files",
  s: "s",
  m: "m",
  h: "h",
  d: "d",
  ago: "ago",
  Done: "Done",
  "You have reached your limit in billing?": "You have reached your limit in billing?",
  "Let Us Solve Your Problem!": "Let Us Solve Your Problem!",
  "Contact us": "Contact us",
  "This page is comming soon": "This page is comming soon",
  "This page is not unknown or does not exsit": "This page is not unknown or does not exsit",
  "Sorry about that, but the page you looking for is not available":
    "Sorry about that, but the page you looking for is not available",
  "Service unavailable": "Service unavailable",
  "This server is temporary busy, try again later":
    "This server is temporary busy, try again later",
  "System Maintenance Scheduled": "System Maintenance Scheduled",
  "We'll be performing system maintenance from": "We'll be performing system maintenance from",
  to: "to",
  Time: "Time",
  "to improve system performance and reliability. During this time, you may experience temporary disruptions to the service. We apologize for any inconvenience this may cause.":
    "to improve system performance and reliability. During this time, you may experience temporary disruptions to the service. We apologize for any inconvenience this may cause.",
  Picture: "Picture",
  "Picture here": "Picture here",
  "List of user accounts managed by admin": "List of user accounts managed by admin",
  "after the street number": "after the street number",
  "to town": "to town",
  "Re-enter your password": "Re-enter your password",
  ...dashboard,
  ...brand,
  ...clientManagement,
  ...billingManagement,
  ...userManagement,
  ...domainManagement,
  ...approval,
  ...notification,
  ...ticket,
  ...setting,
  ...error,
  ...menu,
  ...profile,
  ...message,
  ...dropdown,
  ...authentication,
  ...payment,
  ...activityLog,
  ...require,
  ...status
}

export default translate
